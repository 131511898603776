import { CREATE_PROMOTION_CODE_SUCCESS, GET_PROMOTION_CODES_REQUEST, GET_PROMOTION_CODES_SUCCESS, DELETE_PROMOTION_CODE_SUCCESS } from '../types/promotionCode.types';

function initState() {
    return { promotionCodes: [] };
}

export default function (state = initState(), action) {
    const { type, payload } = action;

    switch (type) {
        case GET_PROMOTION_CODES_REQUEST:
            return {
                ...state,
                promotionCodes: [],
            };

        case GET_PROMOTION_CODES_SUCCESS:
            return {
                ...state,
                promotionCodes: payload.promotionCodes,
            };

        case CREATE_PROMOTION_CODE_SUCCESS:
            return {
                ...state,
                promotionCodes: [payload.promotionCode, ...state.promotionCodes],
            };

        case DELETE_PROMOTION_CODE_SUCCESS:
            return {
                ...state,
                promotionCodes: state.promotionCodes.filter((promotionCode) => promotionCode.id !== payload.promotionCode.id),
            };

        default:
            return state;
    }
}
