import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    *,
    *::after,
    *::before {
        margin: 0;
        padding: 0;
        list-style: none;
        box-sizing: border-box;
        text-decoration: none;
        outline: none !important;
    }
    :root{
        --orange-color: #E46526;
        --body-bg: #f7f7f7;
        --color-primary: #1d3495;
        --color-primary-light-1: rgba(29,52,149,0.11);
        --color-primary-light-2: #4c93e3;
        --color-primary-light-3: #0026c8;
        --color-primary-dark: #02197a;
        --color-primary-dark-1: rgba(29,52,149,0.75);
        --color-primary-dark-2: #176ac7;
        --color-primary-dark-3: #001c96;
        --color-secondary: #31aeac;
        --color-secondary-light-1: rgba(49,174,172,0.2);
        --color-secondary-light-2: rgba(3,130,128,0.21);
        --color-secondary-dark: #097877;
        --color-secondary-dark-1: rgba(49,174,172,0.97);
        --color-secondary-dark-2: rgba(3,130,128,0.79);
        --color-input-border-default: #d2d2d2;
        --color-input-border-focus: var(--color-primary);
        --color-input-label: #74798c;
        --color-input-place-holder: #99a3ac;
        --color-input-error: #f53a6b;
        --box-shadow-input-focus: 0 0 11px 0 rgba(178,209,255,0.49);
        --color-scrollbar: #99a3ac;
        --color-black: #000;
        --color-white: #fff;
        --color-error: #f53a6b;
        --dimen-input-border-radius: 0.25rem;
        --dimen-input-padding: 0.475rem 0.85rem;
        --dimen-button-padding: 0.538rem 1.25rem;
        --font-size-default: 12px;
        --input-size: 0.75rem;
        --transition-button: all 200ms ease-in-out;
        --white-color: #fff;
        --border-radius: 4px;
        --box-shadow: rgba(60, 66, 87, 0.04) 0px 0px 5px 0px, rgba(0, 0, 0, 0.04) 0px 0px 10px 0px;
    }

    html {
        overflow-x: hidden;
    }
    
    body {
        width: 100vw;
        height: 100vh;
        background-color: var(--body-bg);
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .select-mediator {
        width: 80%;
        margin-right: 20%;
        
        > label:first-child {
            padding-left: 0px;
            font-size: 14px;
            color: black;
        }
    }
`;

export default GlobalStyle;
