import moment from 'moment';

// Menu Toggle
export const convertToMainClass = (sidebarClass) => sidebarClass.replace('sidebar', 'main');

export const convertToTogglerClass = (sidebarClass) => sidebarClass.replace('sidebar', 'toggler');

export const joinClassNames = (...classNames) => classNames.join(' ');

export function isDevelopmentMode() {
    return process.env.REACT_APP_NODE_ENV === 'development';
}

export function isNullish(value) {
    return [undefined, null].includes(value);
}

export function isNonEmptyObject(object) {
    return isValidValue(object) && Object.keys(object).length > 0;
}

export function isValidValue(value) {
    return !isNullish(value) && value !== '';
}

export function formatDate(date = moment(), format = 'DD/MM/YYYY') {
    return moment(date).format(format);
}

export function isNullishOrEmpty(value) {
    return isNullish(value) || (Array.isArray(value) && value.length === 0) || (typeof value === 'object' && Object.keys(value).length === 0) || (typeof value === 'string' && value.length === 0);
}
