import React from 'react';
import loadable from '@loadable/component';
import { Icon } from './components/helpers/Icon';

const Login = loadable(() => import('./components/pages/Login'));

const Dashboard = loadable(() => import('./components/pages/Dashboard'));
const VerificationDocuments = loadable(() => import('./components/pages/VerificationDocuments'));
const VerificationDocument = loadable(() => import('./components/pages/VerificationDocument'));
//const Obligateds = loadable(() => import('./components/pages/Obligateds'));
const Organizations = loadable(() => import('./components/pages/Organizations'));
//const ObligatedsContracts = loadable(() => import('./components/pages/ObligatedsContracts'));
const DocumentPreview = loadable(() => import('./components/pages/DocumentPreview'));
const DossiersCEE = loadable(() => import('./components/pages/DossiersCEE'));
const DossierCEE = loadable(() => import('./components/pages/DossierCEE'));
const PromotionCodes = loadable(() => import('./components/pages/PromotionCodes'));
const AccountsActivation = loadable(() => import('./components/pages/Accounts'));
const ProductsVerification = loadable(() => import('./components/pages/ProductsVerification'));
const ProductVerification = loadable(() => import('./components/pages/ProductVerification'));
const ProductPreview = loadable(() => import('./components/pages/ProductPreview'));
const MaPrimeRenov = loadable(() => import('./components/pages/MaPrimeRenov'));

export const DOSSIERS_CEE_ROUTE = '/verification-de-dossiers';
export const ACCOUNTS_ROUTE = '/verification-de-comptes';
export const DOSSIER_CEE_ROUTE = '/verification-de-dossiers/:dossierCEEUUID';
export const PROMOTION_CODES_ROUTE = '/codes-promo';
export const PRODUCTS_ROUTE = '/verification-de-produits';
export const PRODUCT_ROUTE = '/verification-de-produits/:productUUID';
export const PRODUCT_PREVIEW_ROUTE = '/verification-de-produits/:productUUID/apercu';
export const MANDAT_MIXTE_MAPRIMERENOV_ROUTE = '/mandat-mixte-maprimerenov';

const publicRoutes = [
    {
        id: 1,
        title: 'Connexion',
        path: '/connexion',
        component: Login,
        exact: true,
    },
];

const privateRoutes = [
    {
        id: 1,
        title: 'Tableau de bord',
        path: '/tableau-de-bord',
        component: Dashboard,
        icon: () => <Icon name={'dashboard'} />,
        exact: true,
        inSidebar: true,
    },
    {
        id: 2,
        title: 'Verification de comptes',
        path: ACCOUNTS_ROUTE,
        component: AccountsActivation,
        icon: () => <Icon name='accounts_activation' />,
        exact: true,
        inSidebar: true,
    },
    {
        id: 3,
        title: 'Vérification de documents',
        path: '/verification-de-documents',
        component: VerificationDocuments,
        icon: () => <Icon name={'description'} />,
        exact: true,
        inSidebar: true,
    },
    {
        id: 4,
        title: 'Vérification de dossiers',
        path: DOSSIERS_CEE_ROUTE,
        component: DossiersCEE,
        icon: () => <Icon name={'folder'} />,
        exact: true,
        inSidebar: true,
    },
    {
        id: 5,
        title: 'Installateurs',
        path: '/installateurs',
        icon: () => <Icon name={'badge'} />,
        component: Organizations,
        exact: true,
        inSidebar: true,
    },
    /*{
        id: 6,
        title: 'Obligés',
        path: '/obliges',
        component: Obligateds,
        icon: () => <Icon name={'business'}  />,
        exact: true,
        inSidebar: true,
    },
    {
        id: 7,
        title: 'Contrats obligés',
        path: '/contrats-obliges',
        component: ObligatedsContracts,
        icon: () => <Icon name={'file_copy'}  />,
        exact: true,
        inSidebar: true,
    },*/
    {
        id: 8,
        title: 'Vérification document',
        path: '/verification-de-documents/:uuidOrganization/:documentType',
        icon: () => <Icon name={'description'} />,
        component: VerificationDocument,
    },
    {
        id: 9,
        title: 'Aperçu du document',
        path: '/verification-de-documents/:uuidOrganization/apercu',
        icon: () => <Icon name={'description'} />,
        component: DocumentPreview,
    },
    {
        id: 10,
        title: 'Aperçu contrat obligé',
        path: '/contrats-obliges/:uuidOrganization/:uuidContract',
        icon: () => <Icon name={'file_copy'} />,
        component: DocumentPreview,
    },
    {
        id: 11,
        title: 'Vérification de dossier',
        icon: () => <Icon name={'folder'} />,
        path: DOSSIER_CEE_ROUTE,
        component: DossierCEE,
        exact: true,
    },
    {
        id: 12,
        title: 'Abonnement',
        path: PROMOTION_CODES_ROUTE,
        icon: () => <Icon name={'loyalty'} />,
        component: PromotionCodes,
        exact: true,
        inSidebar: true,
    },
    {
        id: 13,
        title: 'Vérification de produits',
        path: PRODUCTS_ROUTE,
        icon: () => <Icon name={'offer'} />,
        component: ProductsVerification,
        exact: true,
        inSidebar: true,
    },
    {
        id: 14,
        title: 'Produit',
        path: PRODUCT_ROUTE,
        component: ProductVerification,
        exact: true,
    },
    {
        id: 15,
        title: 'Aperçu produit',
        path: PRODUCT_PREVIEW_ROUTE,
        component: ProductPreview,
        exact: true,
    },
    {
        id: 16,
        title: 'Mandats MaPrimeRénov’',
        path: MANDAT_MIXTE_MAPRIMERENOV_ROUTE,
        component: MaPrimeRenov,
        exact: true,
        icon: () => <Icon name='offer' />,
        inSidebar: true,
    },
];

export { publicRoutes, privateRoutes };
