export const GET_PROMOTION_CODES_REQUEST = 'GET_PROMOTION_CODES_REQUEST';
export const GET_PROMOTION_CODES_SUCCESS = 'GET_PROMOTION_CODES_SUCCESS';
export const GET_PROMOTION_CODES_FAILURE = 'GET_PROMOTION_CODES_FAILURE';

export const CREATE_PROMOTION_CODE_REQUEST = 'CREATE_PROMOTION_CODE_REQUEST';
export const CREATE_PROMOTION_CODE_SUCCESS = 'CREATE_PROMOTION_CODE_SUCCESS';
export const CREATE_PROMOTION_CODE_FAILURE = 'CREATE_PROMOTION_CODE_FAILURE';

export const DELETE_PROMOTION_CODE_REQUEST = 'DELETE_PROMOTION_CODE_REQUEST';
export const DELETE_PROMOTION_CODE_SUCCESS = 'DELETE_PROMOTION_CODE_SUCCESS';
export const DELETE_PROMOTION_CODE_FAILURE = 'DELETE_PROMOTION_CODE_FAILURE';
