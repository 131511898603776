import {
    GET_ORGANIZATIONS_AND_THEIR_CONTRACTS_SUCCESS,
    GET_ORGANIZATIONS_AND_THEIR_CONTRACTS_FAILURE,
    GET_ORGANIZATION_DOCUMENT_FAILURE,
    GET_ORGANIZATION_DOCUMENT_SUCCESS,
    GET_ORGANIZATIONS_SUCCESS,
    GET_ORGANIZATIONS_FAILURE,
    GET_ORGANIZATION_SUCCESS,
    GET_ORGANIZATION_FAILURE,
    GET_ORGANIZATION_REQUEST,
} from '../types/organization.type';

const initialState = {
    organizationsWithTheirContractsAndSubContractors: [],
    organizations: [],
    document: {},
    currentUserOrganization: null,
    organization: null,
    loading: true,
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_ORGANIZATIONS_AND_THEIR_CONTRACTS_SUCCESS:
            return {
                ...state,
                organizationsWithTheirContractsAndSubContractors: payload,
            };
        case GET_ORGANIZATION_DOCUMENT_SUCCESS:
            return {
                ...state,
                document: payload,
                loading: false,
            };
        case GET_ORGANIZATIONS_SUCCESS:
            return {
                ...state,
                organizations: payload,
            };
        case GET_ORGANIZATIONS_AND_THEIR_CONTRACTS_FAILURE:
            return {
                ...state,
                organizationsWithTheirContractsAndSubContractors: [],
            };
        case GET_ORGANIZATION_DOCUMENT_FAILURE:
            return {
                ...state,
                document: {},
                loading: false,
            };
        case GET_ORGANIZATIONS_FAILURE:
            return {
                ...state,
                organizations: [],
            };
        case GET_ORGANIZATION_REQUEST:
            return {
                ...state,
                currentUserOrganization: null,
                loading: true,
            };
        case GET_ORGANIZATION_FAILURE: {
            return {
                ...state,
                currentUserOrganization: null,
            };
        }
        case GET_ORGANIZATION_SUCCESS:
            return {
                ...state,
                loading: false,
                currentUserOrganization: action.payload,
            };
        default:
            return state;
    }
};
