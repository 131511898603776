import { GET_DOSSIERS_CEE_SUCCESS, GET_DOSSIER_CEE_SUCCESS, SEND_DOSSIER_CEE_SUCCESS } from '../types/dossierCEE.types';

function initState() {
    return { dossiersCEE: [], dossierCEE: {} };
}

export default function (state = initState(), action) {
    const { type, payload } = action;

    switch (type) {
        case GET_DOSSIERS_CEE_SUCCESS:
            return {
                ...state,
                dossiersCEE: payload.dossiersCEE,
            };

        case GET_DOSSIER_CEE_SUCCESS:
            return {
                ...state,
                dossierCEE: payload.dossierCEE,
            };

        case SEND_DOSSIER_CEE_SUCCESS:
            return {
                ...state,
                dossierCEE: payload.dossierCEE,
            };

        default:
            return state;
    }
}
