export const GET_ORGANIZATIONS_AND_THEIR_CONTRACTS_SUCCESS = 'GET_ORGANIZATIONS_AND_THEIR_CONTRACTS_SUCCESS';
export const GET_ORGANIZATIONS_AND_THEIR_CONTRACTS_FAILURE = 'GET_ORGANIZATIONS_AND_THEIR_CONTRACTS_FAILURE';

export const GET_ORGANIZATION_DOCUMENT_SUCCESS = 'GET_ORGANIZATION_DOCUMENT_SUCCESS';
export const GET_ORGANIZATION_DOCUMENT_FAILURE = 'GET_ORGANIZATION_DOCUMENT_FAILURE';

export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS';
export const GET_ORGANIZATIONS_FAILURE = 'GET_ORGANIZATIONS_FAILURE';

export const CONFIRM_DOCUMENT_SUCCESS = 'CONFIRM_DOCUMENT_SUCCESS';
export const CONFIRM_DOCUMENT_FAILURE = 'CONFIRM_DOCUMENT_FAILURE';

export const REJECT_DOCUMENT_SUCCESS = 'REJECT_DOCUMENT_SUCCESS';
export const REJECT_DOCUMENT_FAILURE = 'REJECT_DOCUMENT_FAILURE';

export const GET_ORGANIZATION_REQUEST = 'GET_ORGANIZATION_REQUEST';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_FAILURE = 'GET_ORGANIZATION_FAILURE';
