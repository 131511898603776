export const GET_ORGANIZATIONS_AND_THEIR_PRODUCTS_REQUEST = 'GET_ORGANIZATIONS_AND_THEIR_PRODUCTS_REQUEST';
export const GET_ORGANIZATIONS_AND_THEIR_PRODUCTS_SUCCESS = 'GET_ORGANIZATIONS_AND_THEIR_PRODUCTS_SUCCESS';
export const GET_ORGANIZATIONS_AND_THEIR_PRODUCTS_FAILURE = 'GET_ORGANIZATIONS_AND_THEIR_PRODUCTS_FAILURE';

export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';

export const CONFIRM_PRODUCT_REQUEST = 'CONFIRM_PRODUCT_REQUEST';
export const CONFIRM_PRODUCT_SUCCESS = 'CONFIRM_PRODUCT_SUCCESS';
export const CONFIRM_PRODUCT_FAILURE = 'CONFIRM_PRODUCT_FAILURE';
