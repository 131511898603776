import React from 'react';
import { MaterialIconWrapper } from './MaterialIconWrapper';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const RoundedIconStyle = styled.div`
    span {
        color: var(--color-primary);
        background-color: white;
        border-radius: 25px;
    }
`;

const iconsMapping = {
    description: (props) => <MaterialIconWrapper {...props}>description</MaterialIconWrapper>,
    file_copy: (props) => <MaterialIconWrapper {...props}>file_copy</MaterialIconWrapper>,
    business: (props) => <MaterialIconWrapper {...props}>business</MaterialIconWrapper>,
    badge: (props) => <MaterialIconWrapper {...props}>badge</MaterialIconWrapper>,
    folder: (props) => <MaterialIconWrapper {...props}>folder</MaterialIconWrapper>,
    dashboard: (props) => <MaterialIconWrapper {...props}>dashboard</MaterialIconWrapper>,
    keyboard_backspace: (props) => <MaterialIconWrapper {...props}>keyboard_backspace</MaterialIconWrapper>,
    power_settings_new: (props) => <MaterialIconWrapper {...props}>power_settings_new</MaterialIconWrapper>,
    loyalty: (props) => <MaterialIconWrapper {...props}>loyalty</MaterialIconWrapper>,
    calendar_month: (props) => <MaterialIconWrapper {...props}>calendar_month</MaterialIconWrapper>,
    clear: (props) => <MaterialIconWrapper {...props}>clear</MaterialIconWrapper>,
    accounts_activation: (props) => <MaterialIconWrapper {...props}>verified_user</MaterialIconWrapper>,
    offer: (props) => <MaterialIconWrapper {...props}>local_offer</MaterialIconWrapper>,
};

export const Icon = ({ name, color = 'rgb(101, 117, 183)', fontSize = '24px' }) => {
    const iconProps = { color, fontSize };
    const SelectedIcon = iconsMapping[name];

    return SelectedIcon ? <SelectedIcon {...iconProps} /> : <></>;
};

Icon.propTypes = {
    name: PropTypes.string,
    color: PropTypes.string,
    fontSize: PropTypes.string,
};
